/*******************************************************************************************************************************************************************************************************\

                                           DO NOT TOUCH THIS CODE

\********************************************************************************************************************************************************************************************************/

/***********************************************************************************************\
#1. Gloabal 
\***********************************************************************************************/

body {
    font-family: $site-font;
    color: #000;
    background-color: #fff;
    padding-bottom: 40px;
    @include xs{
        padding-top: 68px;
    }
    @include xs-min{
        padding-top: 80px;
    }
}

html,body,#root{
    height: 100%;
}

.d-lg-flex{
    @include md-min{
        display: flex;
    }
}

.d-flex{
    display: flex;
}

.justify-content-between{
    justify-content: space-between;
}

.align-items-center{
    align-items: center;
}

.img-fluid{
 max-width: 100%;
 width: 100%;
}

.container{
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.text-center{
    text-align: center;
}

.spacer{
    height: 40px;
}