.hero {
    padding-top: 33px;
   
    @include xs{
      background:   linear-gradient(#800088 calc(536px - 90px), #fff 20%, transparent)
    }
    @include xs-min{
        background: linear-gradient($primary-color 71%, #fff 20%, transparent);

    }

    &__left,
    &__right {

        @include md-min {
            width: 100%;
            max-width: 50%;
        }
    }

    &__title {
        color: $primary-font;

        @include md-min {
            font-size: 50px;
        }

        @include md {
            font-size: 28px;
        }
    }

    &__text {
        color: $primary-font;
        margin-bottom: 17px;

        @include md-min {
            font-size: 25px;
        }

        @include md {
            font-size: 18px;
        }
    }
}

.slider {
    margin-top: 50px;
    position: relative;

 
    .carousel-slider .slide{
        &:after{
            content: "";
            height: 30%;
            background: linear-gradient(180deg, transparent 1%, rgba(0, 0, 0, 0.612) 108%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
}

// .w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-animate-right {
    position: relative;
    animation: animateright 0.4s
}
.w3-card,.w3-card-2 {
    box-shadow: 0 2px 5px 0 rgba(155, 11, 11, 0.16),0 2px 10px 0 rgba(53, 154, 25, 0.12)
}
.w3-sidebar {
    height: 60%;
    width: 200px;
    background-color: #ebed60;
    position: fixed!important;
    z-index: 1;
    overflow: auto;
    top: 125px;
    right: 0;
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0
}

.w3-large {
    font-size: 18px!important
}

.w3-button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.w3-right {
    float: right!important
}

.column {
    float: left;
    width: 33.33%;
    padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
    content: "";
    clear: both;
    display: table;
}



