/*------------------------------------*\
    #2. RESET
\*------------------------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
    margin: 0;
    padding: 0;
}

*,
:active,
:focus {
    outline: none !important;
}

ul,
ol {
    list-style: none;
}

textarea {
    resize: none
}

.form-control {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}