.nav{
    background-color: #fff;
    padding: 12px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 12;
    &__buttons-wrapper{

        .primary-btn:first-child{
         margin-right: 13px;;
        }
    }
    &.scrolled{
        box-shadow: 3px 0px 13px 0px rgba(0, 0, 0, 0.15);
    }

    &__logo{
        @include xs{
            max-width: 48px;
        }

    }
}