.title {
  font-weight: 800;
  @include xs{
    font-size: 20px;
  }

  @include xs-min{
    font-size: 24px;
  }

  &::after {
    content: "";
    width: 40px;
    height: 6px;
    border-radius: 5px;
    background-color: $primary-color;
    display: block;
   
    @include xs{
      margin-top: 9px;
    }
    @include xs-min{
      margin-top: 15px;
    }
  }
}

.sub-title {
  font-weight: 600;

  @include xs{
    font-size: 14px;
    margin-top: 18px;
  }

  @include xs-min{
    font-size: 18px;
    margin-top: 23px;
  }
}

.register-link {
  margin-top: 8px;

  .txt {
    font-weight: 400;
  }

  .link {
    font-weight: 700;
  }
}
