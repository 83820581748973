.primary-box{
    background-color: $baseColor;
    max-width: 780px;
    margin: 50px auto 0;
    box-sizing: border-box;
    border-radius: 6px;

    @include xs-min{
        padding: 50px;
    }

    @include xs{    
    padding: 30px 18px;
    }
}