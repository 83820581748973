%placeholder {
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #A0AEC0;
        opacity: 1;
        /* Firefox */
    }

}

.form {
    margin-top: 40px;

    .MuiOutlinedInput-root{
        background-color: #fff;
        box-shadow: 0px 2px 16px 0px rgba(151, 171, 200, 0.11);
    }
}

.label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 7px;
    display: block;
  }

.spanlabel {
    color: red;
    font-weight: 800;
}

.search-label {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 7px;
    display: block;
}
.remember {
    &-input__label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #728AB7;
    }

    &-input__wrapper {
        padding: 0px;
        display: flex;

        .form-check-input {
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 10px;
            width: 36px;
            height: 18px;
            &:checked {
                background-color: #4D77FF;;
                border-color: #4D77FF;;
            }
        }
    }
}

.verify {
    &-input__label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #728AB7;
    }

    &-input__wrapper {
        padding: 0px;
        display: flex;

        .form-check-input {
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 10px;
            width: 56px;
            height: 56px;
            &:checked {
                background-color: #4D77FF;;
                border-color: #4D77FF;;
            }
        }
    }
}

.form-content {
    margin-bottom: 20px;
    @include sm-min{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__inputs {
            width: 100%;
            max-width: calc(50% - 18px);
          }
    }

    &__inputs {
        margin-bottom: 20px;
    }

  }
  

.forgot-link {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #4D77FF;
    border: 0;
    background: transparent;
}

.form-action {

    @include xs-min{
        margin-top: 60px;
    }
    @include xs{
        margin-top: 40px;
    }
  }

  .search-form-content {
    margin-bottom: 20px;
    @include sm-min{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__inputs {
            width: 100%;
            max-width: calc(25% - 18px);
          }
    }

    &__inputs {
        margin-bottom: 20px;
    }

  }