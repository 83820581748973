%btn{
    color:  #FFFFFF;
    font-weight: 600;
    letter-spacing: 0.2px;
    background: $secondary-color;
    border: 0;
    // box-shadow: -5px -4px 9px 2px rgba(255, 255, 255, 0.5), 4px 4px 6px rgba(104, 132, 157, 0.2);
    display: inline-block;

    @include xs-min{
        font-size: 16px;
    }
    @include xs{
        font-size: 12px;
    }
    &:hover,&:focus,&:active {
        background: darken($color: $secondary-color, $amount: 5) !important;
        color:  #FFFFFF !important;
    }
}

.primary-btn {
    
    border-radius: 5px;

    @include xs-min{
        padding: 14px 18px;
    }
    @include xs{
        padding: 11px 14px;
    }
    @extend %btn;

    &--border{
       border: 1px solid $secondary-color;
       background: transparent;
       color: #000;
    }

}



